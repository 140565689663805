import React from 'react';
import Button from '../Button';
import './ContactForm.scss';
import { navigate } from 'gatsby';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    const button_attributes = {
      type: 'submit',
      name: 'submit'
    };

    return (
      <div id="main-content" className="ContactForm">
        <div className="ContactForm__wrapper">
          <div className="ContactForm__content">
            { this.props.children }
          </div>
          <form
            name="contact"
            method="post"
            action="/thank-you"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={ this.handleSubmit }
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className="bot-field">
              <label htmlFor="bot-field">Don’t fill this out:</label>
              <input name="bot-field" id="bot-field" />
            </div>
            <div className="inline">
              <div className="input text">
                <label htmlFor="name-first">First Name</label>
                <input
                  type="text"
                  name="name-first"
                  id="name-first"
                  onChange={ this.handleChange }
                />
              </div>
              <div className="input text">
                <label htmlFor="name-last">Last Name</label>
                <input
                  type="text"
                  name="name-last"
                  id="name-last"
                  onChange={ this.handleChange }
                />
              </div>
            </div>
            <div className="inline">
              <div className="input text required">
                <label htmlFor="email">Email <span className="hidden">(this field is required)</span></label>
                <input type="email" name="email" id="email" required onChange={ this.handleChange } />
              </div>
              <div className="input text">
                <label htmlFor="phone">Phone</label>
                <input type="tel" name="phone" id="phone" onChange={ this.handleChange } />
              </div>
            </div>
            <div className="input text">
              <label htmlFor="company">Company</label>
              <input type="text" name="company" id="company" onChange={ this.handleChange } />
            </div>
            <div className="input checkbox">
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name="demo"
                  id="demo"
                  onChange={ this.handleChange }
                  onClick={ this.handleChange }
                />
                <span className="fake-checkbox" />
              </div>
              <label htmlFor="demo">I would like to request a demo</label>
            </div>
            <div className="input submit">
              <Button color="gold" attributes={ button_attributes }>
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    )
  }
};

export default ContactForm;
