import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import ContactForm from '../components/ContactForm';
import PageTitle from '../components/PageTitle';
import contact_icon from '../img/contactmail_icon.svg';

class ContactPage extends React.Component {
  render() {
    const { siteMetadata } = this.props.data.site;

    return (
      <Layout color="1">
        <Helmet title={`Contact | ${siteMetadata.title}`}>
          <meta name="description" content="Interested in trying ApplyCycle for yourself? Let us know and we'll send you a link to a demo." />
        </Helmet>
        <PageTitle>
          <h1>Contact</h1>
          <img src={contact_icon} alt="text" />
        </PageTitle>
        <ContactForm />
      </Layout>
    )
  }
};

export default ContactPage;

export const query = graphql`
  query ContactQuery {
    ...siteTitle
  }
`
